import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MyOpinionLogo from '../../assets/images/MyOpinion_Logo_Head.png';

export default class NotFound extends Component {
	render() {
		return (
			<>
				<div className="pattern">
					<span className="red"></span>
					<span className="indigo"></span>
					<span className="blue"></span>
					<span className="green"></span>
					<span className="orange"></span>
				</div>
				<div className="auth-main particles_js">
					<div className="auth_div vivify popIn">
						<div className="auth_brand">
							<Link className="navbar-brand" to="/"><img src={MyOpinionLogo} width="150" height="150" className="d-inline-block align-top mr-2" alt="logo" />Oculux</Link>
						</div>
						<div className="card page-400">
							<div className="body">
								<p className="lead mb-3"><span className="number left">404 </span><span className="text">Oops! <br />Page introuvable</span></p>
								<p>La page que vous recherchez est introuvable, veuillez contactez les administrateurs systemes</p>
								<div className="margin-top-30">
									<Link to="/" className="btn btn-round btn-default btn-block">
										<i className="fa fa-arrow-left"></i>
										<span>Retour</span>
									</Link>
									<Link to="/" className="btn btn-round btn-primary btn-block">
										<i className="fa fa-home"></i>
										<span>Tableau de bord</span>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div id="particles-js"></div>
				</div>
			</>
		);
	}
}
