import {USER_TYPE_PAGE, USER_TYPE_USER} from "../../utils/index";

const metisMenu = [
	{
		"id": 1,
		"icon": "icon-home",
		"label": "Tableau de bord",
		"to": "/",
	},
	{
		"id": 2,
		"icon": "icon-pie-chart",
		"label": "Statistiques",
		"content": [
			{
				"id": 2.1,
				"label": "Badges utilisateurs",
				"to": "/stats/users_badges"
			}
		]
	},
	{
		"id": 3,
		"icon": "icon-paper-clip",
		"label": process.env.REACT_APP_SOCIAL_HUB ? "Social Hub" : "MyOpinion",
		"content": [
			{
				"id": 3.1,
				"label": "Utilisateurs",
				"to": `/users?user_type=${USER_TYPE_USER}`,
			},
			{
				"id": 3.2,
				"label": "Pages",
				"to": `/users?user_type=${USER_TYPE_PAGE}`,
			},
			{
				"id": 3.3,
				"label": process.env.REACT_APP_SOCIAL_HUB ? "Posts" : "Opinions",
				"to": "/opinions",
			},
			{
				"id": 3.4,
				"label": "Commentaires",
				"to": "/comments",
			},
			{
				"id": 3.5,
				"label": "Agoras",
				"to": "/agoras",
			},
			{
				"id": 3.6,
				"label": "Reports",
				"to": "/reports",
			},
			{
				"id": 3.7,
				"label": "Awards",
				"to": "/awards",
			},
			{
				"id": 3.8,
				"label": "Verifications",
				"to": "/verifications",
			},
			{
				"id": 3.9,
				"label": "Abonnements",
				"to": "/subscriptions",
			},
		]
	},

	{
		"id": 4,
		"icon": "icon-users",
		"label": "Ressources humaines",
		"content": [
			{
				"id": 4.1,
				"label": "Soldes de conges",
				"to": "/hr/holidays_balance"
			},
		]
	},

	{
		"id": 5,
		"icon": "icon-lock",
		"label": "Securité",
		"content": [
			{
				"id": 5.1,
				"label": "Moderateurs",
				"to": "/moderateurs",
			},
			{
				"id": 5.2,
				"label": "Autorisations",
				"to": "/authorizations",
			},
		]
	},
];

export default metisMenu